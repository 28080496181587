<template>
	<div class="home">
		<div class="content">
			<div class="call-out d-md-flex flex-wrap">

				<v-col class="img col-xs-12 col-md-3">
					<img src="@/assets/hurtKnee.jpg" class="knee-img" alt="Hurt Knee Image"/>
				</v-col>
				<v-col class="text col-xs-12 col-md-9">
					<div class="welcome-title">Welcome {{ nameToShow }}</div>

					<p>This clinic portal is designed to provide easy access to the resources you will need, as well as place
						product orders. For additional information and questions, please don’t hesitate to contact us!</p>
				</v-col>

			</div>
			<div class="home-info">
				<img class="contact-img" src="@/assets/contact.jpg" alt="contact img"/>
				<div class="support-box col-xs-12 col-md-6">
					<div class="label">EMS Bio Support</div>
					General Questions: 801-948-0804<br>
					Billing Questions: 801-613-0634<br>
					info@emsbio.com<br>
					orders@emsbio.com<br>
				</div>
				<div class="info-blurb col-xs-12 col-md-6">
					<img class="fluid-flow-image" src="@/assets/fluidGF2.jpg" alt="Fluid GF"/>
					<div class="info-blurb__content">
						<div class="label">Relevant Conditions:</div>
						<div class="sub-label">
							Degenerative joint disorders such as:
						</div>
						<ul>
							<li>Sports Injuries</li>
							<li>Osteoarthritis</li>
							<li>Joint pain</li>
						</ul>
						<div class="sub-label">Inflammatory conditions such as:</div>
						<ul>
							<li>Bursitis</li>
							<li>Tendonitis</li>
							<li>Fasciitis</li>
						</ul>
						<div class="sub-label">Soft tissue injuries such as:</div>
						<ul>
							<li>Ligament & Tendon sprains</li>
							<li>Muscle & Meniscus tears</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { store } from "@/helpers/store";

export default {
	name: 'PrivateHome',
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'EMS Bio App Home',
		meta: [
			{
				name: 'description',
				content: 'Home Page for Clients and Reps to access their info'
			},
		],
		// all titles will be injected into this template
		titleTemplate: '%s'
	},
	props: {},
	data() {
		return {
			user: {},
		};
	},
	created() {
		// ensure we have the user
		const user = store.get('systemUser')
		if (user) this.setUser(user);
		store.watch('systemUser', user => this.setUser(user.new));
	},
	methods: {
		setUser(user) {
			this.$set(this, 'user', user);
		}
	},
	computed: {
		nameToShow() {
			return this.user.data
					? this.user.data?.company
							? this.user.data.company.practiceName
							: `${this.user.data.name_first} ${this.user.data.name_last}`
					: '';
		}
	},
};
</script>

<style lang="scss" scoped>
.home {
	height: 100%;

	.content {
		height: 100%;

		.fluid-flow-image {
			max-width: 85%;
		}

		.home-info {
			height: 100%;
			display: flex;
			flex-wrap: wrap;

			.contact-img {
				width: 120%;
				opacity: .3;
				position: absolute;
				z-index: 0;
			}

			.support-box {
				flex: 1;
				padding: 3rem;
				z-index: 1;
				font-size: 24px;

				.label {
					font-size: 38px;
					color: #262262;
				}
			}

			.info-blurb {
				flex: 1;
				height: 100%;
				background: white;
				z-index: 1;
				padding-left: 2rem;
				display: flex;
				flex-direction: column;
				align-items: center;

				&__content {
					margin-top: 1rem;
				}

				.label {
					font-size: 28px;
					color: #262262;
				}

				.sub-label {
					font-weight: bold;
				}
			}
		}

		.call-out {
			//display: flex;
			//flex-wrap: wrap;

			.img {
				padding: 0;

				.knee-img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}

			.text {
				padding: 2rem;
				background: #262262;
				color: white;
				font-size: 24px;

				.welcome-title {
					color: #09adee;
					font-size: 34px;
				}

				p {
					margin-top: 1rem;
					font-size: 20px;
				}
			}
		}
	}
}
</style>
